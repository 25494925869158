@import "./variables.scss";

/*-------- Main Styling --------*/

html {
  overflow-x: hidden;

  font-size: 20px;
  @media (min-width: $breakpoint-pc) {
    font-size: 24px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: $font-sm;
  font-family: $normal-font;
  text-shadow:0 0 1px transparent;
  font-weight: 400;
  color: var(--text);
  text-align: center;
  text-decoration: none;
}


h1 {
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin-bottom: 1em;
}

strong {
  font-weight: 700;
  font-style: oblique;
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}





#app-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;
  padding: 4em $padding 3em $padding;

  @media (min-width: $breakpoint-laptop ) {
    padding: 0;
  }
}

body {
  background-color: var(--background);
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 4rem 20px 10vh 20px;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 60%;
    min-width: 768px;
    min-height: 100vh;
    padding: 0;
  }

  @media (min-width: $breakpoint-pc) {
      
  }
}


nav {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
  height: 3rem;
  overflow-x: scroll;
  padding: 0 5px;
  z-index: 100;

  @media (min-width: $breakpoint-laptop) {
      position: fixed;
      left: 0;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
      width: auto;
      min-width: unset;
      padding: unset;
      margin-left: 10px;
      margin-top: 50vh;
      transform: translateY(-50%);
      overflow-x: unset;
  }
}
.nav-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  min-height: 2rem;
  font-size: 1rem;

  @media (min-width: $breakpoint-laptop) {
    min-width: 12vw;
  }
  
  span {
    position: relative;
    color: var(--text);
    transition: font-size 0.15s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      right: 50%;
      display: block;
      width: 0;
      height: 1px;
      background-color: var(--text);
      transition: width 0.15s ease-out, right 0.15s ease-out;
    }

    &:hover {
      &::after {
        width: 100%;
        right: 0;
      }
    }
  }
}
.activeLink {
  span {
    font-size: 1.3rem;

    &::after {
      display: none;
    }
  }
}


.page-description {
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 95%, 1);
  margin-bottom: 20px;

  line-height: 1.6em;
  font-size: $font-sm;

  html[data-theme='dark'] & {
    border-bottom: 1px solid hsla(0, 0%, 25%, 1);
  }


  @media (min-width: $breakpoint-laptop) {
    width: 70%;
    margin-top: 20px;
    padding: 20px;
  }
}
