@import "./variables.scss";


.home-splashscreen {
  max-width: 100%;
  
  @media (min-width: $breakpoint-laptop) {
    margin-left: 100px;
  }


  .header {
    width: 100%;
    font-family: $Chonburi;
    font-size: 4rem;
    line-height: 1;
    background-color: #000;
    color: transparent;
    text-shadow: 4px 2px 4px hsla(0,0%,100%,.3);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;

    @media (min-width: $breakpoint-laptop) {
      font-size: 5rem;
    }

    @media (min-width: $breakpoint-pc) {
        font-size: 6rem;
    }
  }
  html[data-theme='dark'] & .header {
    background-color: #bbbbbb;
    color: transparent;
    text-shadow: 4px 2px 4px hsla(0,0%,0%,.3);
  }
 
  p.tagline {
    font-size: $font-lg;
    margin: 0.4em 0 1.2em 0;
  }

  ul.technologies {
    display: none;    

    @media (min-width: $breakpoint-laptop) {
      display: flex;
      justify-content: center;
      list-style: none;
      flex-wrap: wrap;
      margin: 0 auto;
      width: 100%;
  }

    li {
      display: block;
      flex: 0 0 auto;
      font-size: $font-sm;
      margin: 0 1em;

      @media (min-width: $breakpoint-laptop) {
        flex: 0 0 auto;
        margin: 0 1em 0 0;

        &::before {
          content: '•';
          padding-right: 1em;
        }
        &:first-of-type::before {
            display: none;
        }
        &:last-of-type {
          margin-right: 0;
      }
      }

    }
  }
}
