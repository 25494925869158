@import "../../variables.scss";

.gallery-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 100%;
  line-height: 1.5;

  @media (min-width: $breakpoint-pc ) {
    width: 80%;
  }

  .gallery-card {
    max-width: 100%;
    height: 100%;
    margin: $padding auto ($padding*2) auto;

    h2 {
      margin-bottom: 0.3em;
      text-align: center;
      font-weight: 600;
    }

    a {
      display: inline-block;
    }
    img {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    
    div {
      font-size: $font-xs;
      margin: 0 auto;
    }
   
  }

}


.lg-container {
  .lg-backdrop {
    background-color: var(--background);
  }

  .lg-content {
    top: 0 !important;
  }
  
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    transform: translate3d(-50%, 0, 0);
  }

  .lg-prev, .lg-next {
    background-color: transparent;
    color: var(--text);
  };

  .lg-toolbar .lg-icon {
    color: var(--text);
  }
}

