@import "../variables.scss";

.themeswitch {
    position: absolute;
    bottom: 5px;
    left: 50%;
    display: flex;
    width: fit-content;
    flex-direction: row;
    transform: translateX(-50%);
   
    @media (min-width: $breakpoint-pc ) {
        position: fixed;
        left: 95vw;
        bottom: 50vh;
        flex-direction: column;
        transform: translateY(50%);
    }

    .mode {
        display: block;
        padding: 5px;
        background-color: transparent;
        border: none;
        font-size: 20px;

        &:not(.active) {
            cursor: pointer;
        }

        /* Lightmode */
        &.light {
            .material-symbols-outlined {
                color: #ccc;
            }
        }
        &.dark { 
            .material-symbols-outlined {
                color: #777;
            }
        }

        /* Darkmode */
        html[data-theme='dark'] & {
            &.light {
                .material-symbols-outlined {
                    color: #aaa;
                }
            }
            &.dark { 
                .material-symbols-outlined {
                    color: #333;
                }
            }
        }
        
    }
}