@import "../variables.scss";

.projects-gallery {
  width: 100%;
}

.project-card {
  display: flex;

  @media (min-width: $breakpoint-laptop ) {
    position: relative;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);

    &:hover,
    &:focus,
    &:focus-within { 
      /* hift card for emphasis and space for description when hovered */
      .project-overview {
        @media (min-width: $breakpoint-laptop) {
          margin-left: 12.5%;
        } 
        @media (min-width: $breakpoint-pc) {
          margin-left: 0;
        }  
      }
      .project-description {
        visibility: visible;
        opacity: 1;
        right: 0;

        @media (min-width: $breakpoint-pc) {
          right: -10%;
        }  
      }
    }
  }

  @media (min-width: $breakpoint-pc) {
    width: 70%;
  }  
}


.project-overview {
  width: 100%;
  height: auto;
  margin: 10px 0;
  padding: 10px;
  box-shadow: 2px 2px 15px hsla(0, 0%, 70%, 1);
  border-radius: 10px;
  background: #FFF;

  html[data-theme='dark'] & {
    background-color: var(--background-darker);
    box-shadow: 4px 4px 12px hsla(0, 0%, 10%, 1);

    img {
      filter: brightness(80%);
    }
  }

  @media (min-width: $breakpoint-laptop ) {
    width: 52.5%;
    margin: 20px 0 20px 22.5%;
    transition: margin-left 0.3s ease; // transition for project-overview sliding to left
  }

  @media (min-width: $breakpoint-pc) {
    width: 65%;
    margin: 20px 0 20px 22.5%;
  }

  header {
    h2 {
      font-weight: 700;
      html[data-theme='dark'] & {
        color: #d3d3d3;
      }
    }
    span {
      html[data-theme='dark'] & {
        color: #d3d3d3;
      }
    }
  }
  
  
  img {
    width: 100%;
    max-height: 100%;
    margin-top: 5px;
    border: 1px solid hsla(0, 0%, 90%, 1);
    border-radius: 10px;
  }
  label {
    display: none;
  }
  header {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}




// project-description for Mobile/Tablet
.project-card > .project-description { // Hide project-description for the pc layout
  display: none;
}
.project-overview > .project-description {
  max-height: 0;
  visibility: hidden;
  opacity: 0;

  transition: max-height 0.3s, opacity 0.5s linear; // transition for Show more/Show less

  display: flex;
  flex-direction: column;
  margin-top: 10px;

  p {
    text-align: left;
  }

  a {
    text-align: left;
  }
}
.project-description.expandedCard {
  max-height: 100vh;
  visibility: visible;
  opacity: 1;
}


// project-description for laptop+
@media (min-width: $breakpoint-laptop) {
  .project-card > .project-description {
    display: flex;
  }
  .project-overview > .project-description { // Hide project-description for the mobile layout
    display: none;
  }

  .project-description {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    right: -60%;
    width: 35%;
    min-height: 100%;
    padding: 10px 10px 10px 40px;
    visibility: hidden;
    opacity: 0;
    transition: right 0.3s ease-out, opacity 0.3s ease-in; // transition for description sliding in and becoming visible

    p {
      text-align: left;
    }

    a {
      font-size: 20px;
      text-align: left;
      line-height: 1.3em;
    }
  }
}


// project-description for PC
@media (min-width: $breakpoint-pc) {
  .project-card > .project-description {
    display: flex;
  }
  .project-overview > .project-description { // Hide project-description for the mobile layout
    display: none;
  }

  .project-description {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    right: -60%;
    width: 45%;
    min-height: 100%;
    padding: 10px 10px 10px 40px;
    visibility: hidden;
    opacity: 0;
    transition: right 0.3s ease-out, opacity 0.3s ease-in; // transition for description sliding in and becoming visible

    p {
      text-align: left;
    }

    a {
      font-size: 20px;
      text-align: left;
      line-height: 1.3em;
    }
  }
}

.expand-button {
  cursor: pointer;
  color: $lighter-grey;
}
@media (min-width: $breakpoint-pc) { // Hide expand button for mobile layout
  .expand-button {
    display: none;
  }
}

strong p { // Styling for github repo links
  font-weight: 400;
  font-style: oblique;
}
