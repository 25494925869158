// Normal font
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$normal-font: 'PT Sans', sans-serif;

// Decorative fonts
@import url('https://fonts.googleapis.com/css2?family=Chonburi&display=swap');
$Chonburi: 'Chonburi', cursive;

$font-xs: 0.7rem;
$font-sm: 0.9rem;
$font-md:   1rem;
$font-lg: 1.2rem;
$font-xl: 1.4rem;


// Responsive layout breakpoints
$breakpoint-laptop: 1024px; 
$breakpoint-pc: 1600px;


// Misc
$padding: 15px;


//Colors
$near-black: #333;
$darker-grey: #555;
$lighter-grey: #888;
$lightest-grey: #ccc;

:root {
    --background: #fff;
    --text: #333;
    --primary: #555;
    --secondary: #888;
}

html[data-theme='light'] {
    --background: #fff;
    --text: #333;
    --primary: #555;
    --secondary: #888;
}

html[data-theme='dark'] {
    --background-darker: #333;
    --background: #222;
    --text: #bbb;
    --primary: #555;
    --secondary: #888;
}
