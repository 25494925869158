@import "./variables.scss";

main {
  width: 85%;
  padding-left: 10%;
}

@media (min-width: $breakpoint-laptop) {
  #app-container > #portfolio-container {
    max-width: calc( 100% - 12vw );
    box-shadow: none;
  }
}
