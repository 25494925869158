@import "./variables.scss";

#app-container {
  #about-page {
    max-width: 700px;
    line-height: 1.5;
  
    @media (min-width: $breakpoint-pc ) {
      padding: 100px 0;
    }
    
    h2 {
      margin-bottom: 1em;
      text-align: left;
      font-weight: 600;
    }
  
    p {
      text-align: left;
    }

    .techtools {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin: 1rem auto;
    
      @media (min-width: $breakpoint-pc ) {
        flex-direction: row;
        align-items: flex-start;
      }
    
      .aligned-ul {
        display: block;
        width: fit-content;
        min-width: 300px;
        padding: 1em;
        background-color: #f3f6ff;
        border-radius: 10px;
        
        @media (min-width: $breakpoint-pc ) {
          min-width: 300px;
        }
    
    
        h2 {
          text-align: center;
          font-size: 110%;
          font-weight: 700;
          margin-bottom: 0.5rem;
        }
        ul {
          list-style-type: circle;
          list-style-position: inside;
        }
        li {
          text-align: left;
        }
      }
      html[data-theme='dark'] & .aligned-ul {
        background-color: var(--background-darker);
      }
    }
  }
}





