@import "./variables.scss";

.contact {
  max-width: 100%;

  * {
    font-size: $font-xl;
  }
  p {
    margin: 20px;
    font-size: $font-lg;
  }

  a.email {
    position: relative;
    display: inline-block;
    font-size: 2rem;
    color: $near-black;

    html[data-theme='dark'] & {
      color: var(--text);
    }

    @media (min-width: $breakpoint-pc) {
      font-size: 3rem;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 50%;
      display: block;
      width: 0;
      height: 4px;
      background-color: $near-black;
      transition: width 0.15s ease-out, right 0.15s ease-out;

      html[data-theme='dark'] & {
        background-color: var(--text);
      }
    }

    &:hover {
      &::after {
        right: 0;
        width: 100%;
      }
    }
  }
}
